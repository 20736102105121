import {useRef, useState} from 'react';
import classes from './CheckOut.module.css';

const isEmpty= value=> value.trim()==='';
const isFiveChars = value=> value.trim().length ===6;

const CheckOut = (props) => {
    const [formInputsValidity,setFormInputsValidity]= useState({
        name:true,
        street:true,
        city: true,
        postalCode:true

    });
    const nameInputRef=useRef();
    const streetInputRef=useRef();
    const postalCodeInputRef=useRef();
    const cityInputRef=useRef();
  const confirmHandler = (event) => {
    event.preventDefault();

    const enteredName= nameInputRef.current.value;
    const enteredStreet= streetInputRef.current.value;
    const enteredPostalCode= postalCodeInputRef.current.value;
    const enteredCity= cityInputRef.current.value;

    const enteredNameIsValid = !isEmpty(enteredName);
    const enteredStreetIsValid = !isEmpty(enteredStreet);
    const enteredCityIsValid= !isEmpty(enteredCity);
    const enteredPostalCodeIsValid= isFiveChars(enteredPostalCode);

    setFormInputsValidity({
        name: enteredNameIsValid,
        street: enteredStreetIsValid,
        city:enteredCityIsValid,
        postalCode:enteredPostalCodeIsValid


    })

    const formIsValid= enteredNameIsValid && enteredCityIsValid && enteredPostalCodeIsValid && enteredStreetIsValid;

    if(!formIsValid){
        return;
    }
    props.onConfirm({
      name:enteredName,
      street:enteredStreet,
      city: enteredCity,
      postalCode:enteredPostalCode,
    });

  };

  return (
    <form className={classes.form} onSubmit={confirmHandler}>
      <div className={classes.control}>
        <label htmlFor='name'>Your Name</label>
        <input type='text' id='name' ref={nameInputRef}/>
        {!formInputsValidity.name && <p>please enter a valid name</p>}
      </div>
      <div className={classes.control}>
        <label htmlFor='street'>Street</label>
        <input type='text' id='street' ref={streetInputRef
        } />
         {!formInputsValidity.street && <p>please enter a valid street</p>}
      </div>
      <div className={classes.control}>
        <label htmlFor='postal'>Postal Code</label>
        <input type='text' id='postal' ref={postalCodeInputRef
        }/>
         {!formInputsValidity.postalCode && <p>please enter a valid postal code</p>}
      </div>
      <div className={classes.control}>
        <label htmlFor='city'>City</label>
        <input type='text' id='city' ref={cityInputRef
        } />
         {!formInputsValidity.city && <p>please enter a valid city</p>}
      </div>
      <div className={classes.actions}>
        <button type='button' onClick={props.onCancel}>
          Cancel
        </button>
        <button className={classes.submit}>Confirm</button>
      </div>
    </form>
  );
};

export default CheckOut;